.table
  display: flex
  flex-direction: column
  width: 100%
  margin: auto
.table__tr
  display: flex
  justify-content: space-between
  text-align: left
  border: 1px solid #4c4c4c
.table__th
  width: 20%
  list-style: none
.table__td 
  gap: 10px;
  display: flex;
.table__ok
  background-color: #4d9f6f;
  color: #fff;
  height: 270px;
.table__td_price
  flex-direction: column
  flex-wrap: wrap