.header
  grid-area: header
  display: flex
  justify-content: flex-end
  align-items: center
  height: 100px;
  margin: 30px auto;
  width: 100%;
  border-image-slice: 1;
  box-sizing: border-box
  background-image: linear-gradient(to bottom, #fff, #4d9f6f);
  position: fixed;
  top: 0;
  left: 0;
.header__link_logo
  width: 200px
  margin: 20px
  object-fit: contain
.header__logo
  width: 100%
.header__slogan
  font-size: 26px
  text-align: center
  font-weight: 600
  //text-shadow: 3px 0 rgba(250, 111, 142, .5), 6px 0 rgba(250, 111, 142, .4), 9px 0 rgba(250, 111, 142, .3), 12px 0 rgba(250, 111, 142, .2), 15px 0 rgba(250, 111, 142, .1);
  box-sizing: border-box
  overflow: hidden
  margin: 0
.header__lk
  margin-top: 10px
.header__link_type_profile
  background-image: url(../../../images/profile-icon.png)
  padding: 8px 20px
  background-color: #F9F9F9
  border-radius: 5px
  background-position: center;
  background-repeat: no-repeat;
  background-color: inherit;
  background-size: 20px
.header__link
  text-decoration: none;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: inherit;