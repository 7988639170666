.activ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 16px;
  padding: 0;
}

.activ_id {
  width: 200px;
  list-style: none;
}

.activ_item {
  width: 50px;
  list-style: none;
}

.activ_data {
  width: 80px;
  list-style: none;
}
