.menuweek-button {
  height: 40px;
  border: 0;
}

.menuweek-button {
  width: 250px;
}

.button__type {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.menuweek-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.modal {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  overflow: auto;
  -webkit-transition: visibility 0.5s, opacity 0.5s;
  transition: visibility 0.5s, opacity 0.5s;
}

.modal_opened {
  visibility: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  z-index: 10;
}

.modal__close {
  background-image: url(../images/close-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  background-color: transparent;
  width: 30px;
  height: 30px;
  position: absolute;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  padding: 0;
  top: 0;
  right: 0;
}

.modal__container {
  min-width: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 1;
  position: relative;
  margin: auto;
}

.modal__container__type_add-recipe {
  width: 95%;
  margin-top: 20px;
}

.form {
  width: 90%;
  margin: 10px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form__fieldset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 10px;
  gap: 10px;
  margin: 10px auto;
  width: 100%;
  text-align: center;
}

.form__fieldset_column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.form__label {
  position: relative;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.form__label_type_row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.form__input {
  display: block;
  width: 100%;
  margin: 0;
  height: 40px;
  padding: 2px 5px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form__input:focus {
  outline: 0;
  outline-offset: 0;
}

.form__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
  margin: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form__block_time {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.form__block_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 5px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
}

.form__block_row_nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.form__block_row_wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.form__heading {
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  margin: 0;
}

.form__input_error {
  color: red;
}

.form__title {
  margin: 0;
  padding: 0;
  text-align: center;
}

.form__fieldset_type_measure {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.form__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0;
  margin: 0;
  gap: 5px;
  font-size: 20px;
  font-weight: 600;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.form__label_type_measure {
  width: 18%;
}

.form__label_type_title {
  width: 48%;
}

.form__label_type_category {
  width: 48%;
}

.form__label_type_url {
  width: 90%;
  margin: 10px auto;
}

.form__label_type_activeCooking {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.form__add-step {
  padding-left: 30px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.form__block_column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.form__input_type_activeCooking {
  max-width: 120px;
  margin: 6px 0;
}

@media screen and (max-width: 767px) {
  .form__label_type_title {
    width: 100%;
  }
  .form__label_type_category {
    width: 100%;
  }
  .form__fieldset {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.color {
  background-color: #4D9F6F;
  color: #fff;
}

.font16Norm, .font16M, .font18Bold, .font20Norm, .font20M, .font20Bold, .font24Bold, .font24Norm, .font26Norm, .font26Bold, .font35Bold {
  font-family: 'Inter';
  line-height: 1;
  font-style: normal;
}

.font16Norm {
  font-weight: 400;
  font-size: 16px;
}

.font16M {
  font-weight: 500;
  font-size: 16px;
}

.font18Bold {
  font-weight: 700;
  font-size: 18px;
}

.font20Norm {
  font-weight: 400;
  font-size: 20px;
}

.font20M {
  font-weight: 500;
  font-size: 20px;
}

.font20Bold {
  font-weight: 700;
  font-size: 20px;
}

.font24Bold {
  font-weight: 700;
  font-size: 24px;
}

.font24Norm {
  font-weight: 400;
  font-size: 24px;
}

.font26Norm {
  font-weight: 400;
  font-size: 26px;
}

.font26Bold {
  font-weight: 700;
  font-size: 26px;
}

.font35Bold {
  font-weight: 700;
  font-size: 35px;
}

@media screen and (max-width: 1024px) {
  .font35Bold {
    font-size: 32px;
  }
  .font26Bold {
    font-size: 22px;
  }
  .font24Norm {
    font-size: 20px;
  }
  .font20Norm {
    font-size: 18px;
  }
  .font20Bold {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .font35Bold {
    font-size: 26px;
  }
  .font26Norm {
    font-size: 20px;
  }
  .font24Norm {
    font-size: 18px;
  }
  .font24Bold {
    font-size: 18px;
  }
  .font20Norm {
    font-size: 16px;
  }
  .font20M {
    font-size: 16px;
  }
  .font18Bold {
    font-size: 14px;
  }
}

@media screen and (max-width: 520px) {
  .font35Bold {
    font-size: 24px;
  }
  .font16Norm {
    font-size: 14px;
  }
  .font26Norm {
    font-size: 16px;
  }
  .font20Norm {
    font-size: 12px;
  }
  .font20M {
    font-size: 12px;
  }
  .font24Norm {
    font-size: 14px;
  }
  .font16M {
    font-size: 10px;
  }
}

.root {
  min-width: 303px;
  max-width: 1920px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-size: cover;
  background-position-x: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  color: #444;
  width: 100%;
}

.page p {
  margin: 0;
}

.item__link {
  opacity: 0.8;
  cursor: pointer;
}

.gg-delete, .gg-edit, .gg-heart, .gg-list, .gg-close, .gg-save, .gg-copy, .gg-cross, .gg-timing, .gg-add, .gg-sort, .gg-accept, .gg-no-accept {
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.gg-delete {
  background-image: url(../images/delete.svg);
  margin-left: auto;
}

.gg-delete:hover {
  cursor: pointer;
  opacity: 0.6;
}

.gg-edit {
  background-image: url(../images/iconEdit.png);
}

.gg-heart {
  background-image: url(../images/heart.svg);
}

.gg-list {
  background-image: url(../images/add-list.svg);
}

.gg-close {
  background-image: url(../images/close.svg);
  position: absolute;
  right: 5px;
  top: 5px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  padding: 0;
}

.gg-save {
  background-image: url(../images/save.png);
}

@media screen and (max-width: 320px) {
  .gg-save {
    width: 20px;
  }
}

.gg-copy {
  background-image: url(../images/copy.png);
}

@media screen and (max-width: 320px) {
  .gg-copy {
    width: 20px;
  }
}

.gg-cross {
  margin: 0;
  background-image: url(../images/cross.png);
}

.gg-timing {
  background-image: url(../images/time.svg);
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 32px;
}

.gg-add {
  background-image: url(../images/add-r.svg);
  padding-left: 25px;
  width: 200px;
  margin: 10px auto;
  text-align: center;
  font-size: 26px;
}

.gg-sort {
  background-image: url(../images/sort.png);
  width: 20px;
  padding-left: 25px;
}

.gg-sort:hover {
  cursor: pointer;
  opacity: 0.6;
}

.gg-accept {
  background-image: url(../images/zelenayagaloshka.svg);
  font-size: 26px;
}

.gg-no-accept {
  background-image: url(../images/krasniykrestik.svg);
}

.sidebar {
  grid-area: sidebar;
}

.file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}

.content {
  width: 90%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header {
  grid-area: header;
}

.footer {
  grid-area: footer;
}
