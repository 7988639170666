.range__label {
  width: 70%;
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
}

@media (max-width: 540px) {
  .range__label {
    margin: 20px auto;
    width: 90%;
  }
}

.range__input {
  accent-color: #4D9F6F;
  -webkit-appearance: none;
  width: 100%;
}

.range__input::-webkit-slider-runnable-track {
  border-radius: 10px;
  width: 100%;
  height: 10px;
  border: 1px solid #fff;
  background-color: #4D9F6F;
}

.range__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  border: 8px solid #4D9F6F;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: -10px;
}
