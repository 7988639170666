.recipe__delete, .recipe-card__back {
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: 0;
  width: 25px;
  height: 22px;
}

.recipe-list {
  width: 90%;
  margin: auto;
  display: -ms-grid;
  display: grid;
  gap: 20px;
  -ms-grid-columns: (minmax(250px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.recipe__post {
  min-width: 300px;
  max-width: 320px;
  width: 25%;
  margin: 20px 10px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px;
  min-height: 200px;
}

.recipe__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  color: #FFF;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.recipe-image {
  -o-object-fit: cover;
     object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.recipe-image__place_list {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}

.recipe__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  border-bottom: 1px solid grey;
  width: 80%;
}

.recipe-card__title {
  text-decoration: none;
  text-overflow: ellipsis;
  color: #020202;
  margin: auto;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 40px;
}

.recipe__info-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  list-style: none;
  width: 90%;
  margin: auto;
}

.recipe-image__place_card {
  width: 450px;
  height: 450px;
}

.recipe-card__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 50px;
  width: 100%;
  border: red solid 1px;
}

.recipe-card__ing {
  width: 45%;
}

.recipe-card__ingredients {
  text-align: center;
  margin: 0;
}

.recipe__ing-list {
  margin-bottom: 5px;
}

.recipe__messure {
  padding-left: 5px;
}

.recipe__ing {
  border-bottom: #ced0e3 solid 1px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  line-height: 2;
  white-space: nowrap;
  font-size: 18px;
}

.recipe-card__energyvalue_title {
  text-align: center;
}

.recipe__ing-list::before {
  border: red solid 1px;
}

.recipe-card__energyvalue_text {
  margin: 0;
}

.recipe__product {
  margin: 15px;
  padding-left: 15px;
}

.recipe-card__energyvalue-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  list-style: none;
  text-align: center;
}

.recipe-card__energyvalue_name {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  margin: 0;
}

.recipe-card__energyvalue_quantity {
  margin: 0;
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
}

.recipe-card__energyvalue_messure {
  margin: 0;
}

.recipe-card__energyvalue-lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.recipe-card__energyvalue {
  width: 80%;
  margin: 20px auto;
  border: 1px solid #929ba3;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.recipe__description {
  width: 80%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.recipe__description_title {
  text-align: center;
  font-size: 26px;
  line-height: 1.5;
  font-weight: 600;
}

.recipe__description_step {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 400;
}

.recipe__description_span {
  line-height: 1.5;
  font-size: 22px;
  font-weight: 600;
  padding-right: 20px;
}

.recipe__add-like {
  right: 10px;
  top: 10px;
  background-image: url(../../../images/Vector.svg);
}

.recipe__icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10px;
  margin-top: auto;
  width: 100%;
}

.recipe__add-like:hover {
  cursor: pointer;
  opacity: 0.3;
}

.recipe__delete {
  right: 10px;
  top: 20px;
  background-image: url(../../../images/icondelete.png);
}

.recipe-card__back {
  background-image: url(../../../images/arrow-long-left.svg);
  margin-left: 40px;
}

.recipe__add {
  right: 8px;
  top: 40px;
  background-image: url(../../../images/kisspng-computer-icons-5ae5ff28b4e7a1.709225111525022504741.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: 0;
  width: 25px;
  height: 22px;
}

.recipe__add:hover {
  cursor: pointer;
  opacity: 0.3;
}

.recipe__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 90%;
  margin: 40px auto;
}

.recipe-card {
  width: 95%;
  margin: auto;
  background-color: #fff;
}

.tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 10px;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.tag-teal {
  background-color: #47bcd4;
}

.recipe-card__category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  color: #f9326d;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 700;
  padding: 15px 0;
}

.recipe-card__category:hover {
  text-decoration: underline;
}
