.activ
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  font-size: 16px
  padding: 0;
.activ_id
  width: 200px;
  list-style: none
.activ_item
  width: 50px;
  list-style: none
.activ_data
  width: 80px;
  list-style: none