.data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
