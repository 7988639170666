.form-settings 
  width: 80%
  margin: auto
  position: relative
  margin-top: 100px
  color: #000
.form-settings__heading
  margin-bottom: 20px
  padding: 0px
.form-settings__text
  text-align: center
  @media screen
    @media (max-width: 520px)
      margin: 5px
      font-size: 14px
.form-settings__subtitle
  color: #7C7C7C
  cursor: pointer
.form-settings__form
  display: flex
  position: relative
  z-index: 1;
  flex-direction: column 
  border-radius: 0px 28px 28px 28px;
  box-shadow: 1px 5px 25px rgba(0, 0, 0, 0.5);
  margin: 52px auto 100px
.form-settings__fieldset
  display: flex
  justify-content: space-between
  padding: 0
  box-sizing: border-box
  border: 0
  width: 90%
  margin: 0 auto 50px
  align-items: baseline;
  &:first-child
    margin: 25px auto
.recipe__kitchenware_item
  list-style: none;
  display: flex;
  padding: 0;
  align-items: center;
  gap: 10px;
  flex: 1 0 48%;
  justify-content: flex-start;
.form-settings__label
  position: relative
  margin: 15px 0
  display: flex
  align-items: center
  width: 49%
  justify-content: flex-start;
  gap: 20px;
  @media screen
    @media (max-width: 520px)
      flex-direction: column;
      width: fit-content
.form-settings__title
  margin: 0 0 10px
.form-settings__block
  display: flex
  position: relative
  width: 100%
  flex-wrap: wrap
  gap: 5px
  flex-direction: column
  margin: 0 auto
.form-settings__submit
  border-radius: 28px
  box-shadow: 1px 1px 25px 0px rgba(0, 0, 0, 0.25);
  width: 200px;
.form-settings__input_time
  max-width: 60px
  margin: 0 5px
  height: 30px
  text-align: center
  border-width: 1px;
  border-style: solid;
  border-color: #7C7C7C;
.form-settings__kitchenware
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  gap: 20px
.form-settings__autocomplete
  display: flex
  flex-direction: column
  width: 100%
  position: absolute
  background-color: #fff
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
  top: 40px
  z-index: 1
.form-settings__autocomplete_text
  padding: 5px 22px  
  &:hover
    background-color: #f9326d
.button__type
  display: flex
  align-items: center
  list-style: none
  text-align: center
  padding: 10px;
  border: 1px solid #7C7C7C;
  border-radius: 5px; 
.button__type_active 
  border: 0
  background-color: #353d38;
  color: #fff
.form-settings__time
  display: flex
  align-items: center
  justify-content: center
.form-settings__block_row
  display: flex
  flex-wrap: wrap
  gap: 20px
  justify-content: flex-start
  margin: 20px 0 0
  align-items: center
  padding: 0
  position: relative
  @media screen
    @media (max-width: 520px)
      justify-content: flex-start
.meals__checkbox
  position: absolute
  width: 1px
  height: 1px
  overflow: hidden
  clip: rect(0 0 0 0)
  &:checked + .visible-checkbox
    background-image: url('../../../images/Ellipse4.png')
.visible-checkbox
  display: inline-block
  vertical-align: middle
  width: 30px
  height: 30px
  flex-shrink: 0
  flex-grow: 0
  border: 1px solid #000
  border-radius: 50%
  margin-right: 0.5em
  background-repeat: no-repeat
  background-position: center center
  background-size: 24px;
.ingredient__stop
  margin-bottom: 5px
  list-style: none
  display: flex
  gap: 10px
  justify-content: center
  align-items: center
  padding: 0
.ingredient__name
  margin: 0
  display: flex
  justify-content: space-between
.form-settings__list
  display: flex
  gap: 5px 10px;
  margin: 5px 0;
  flex-wrap: wrap;
.form-settings__meals
  width: 100%
  font-weight: 600
  text-align: center
  margin: 5px auto
  color: #7C7C7C;
  text-align: left;
.form-settings__block_column
  margin: 10px auto
.form-settings__degree
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  gap: 5px
.buttons__block
  display: flex
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 20px;
.attention__icon
  background-image: url('../../../images/attention.png')
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  background-size: contain;
  position: relative
.form-settings__graph
  background: #FFFFFF;
  box-shadow: 1px 5px 25px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 10px 10px;
  padding: 22px
  margin: 20px 0 0;
.form-settings_type_plans
  margin-top: 0
.form-settings__fieldset__cooking
  position: relative
  @media screen
    @media (max-width: 420px)
      flex-wrap: wrap
      justify-content: center
      width: 100%
.option
  display: flex
  margin: -40px 0 0
.meals__label
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
@media screen
  @media (max-width: 1024px)
    .form-settings
      margin-top: 10px
      width: 96%;
    .form-settings__fieldset:first-child
      margin: 20px auto;
  @media (max-width: 768px)
    .form-settings__heading
      margin: 10px 0 10px; 
      text-align: center
    .form-settings__subtitle
      margin: 10px 0 0
    .form-settings__form
      margin: 20px auto
    .form-settings__fieldset
      margin: 10px auto 0; 
      flex-wrap: wrap;
      width: 96%
    .button__type
      height: 30px
      padding: 0 5px;
    .form-settings__block_row
      gap: 10px
      margin: 0
    .form-settings__graph
      padding: 0
      margin: 0
      box-shadow: none;
    .form-settings__degree
      margin: 0 0 10px
    .form-settings__block
      margin: 0
  @media (max-width: 540px)
    .button__settings
      margin: 0 auto 10px;
    .form-settings__form
      box-shadow: none
    .form-settings__fieldset
      flex-wrap: nowrap
    .form-settings__fieldset:first-child
      margin: 0
    .form-settings__kitchenware
      gap: 10px;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column
    .form-settings__title
      margin: 0 auto 5px
      text-align: center
    .form-settings_cals
      flex-direction: column
      align-items: center;
    .form-settings__label_time
      flex-wrap: wrap
      gap: 5px;
      margin: 5px;
