.recipes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 95%;
  margin: auto;
}

.table__tr_recipe {
  border: 1px solid #4c4c4c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left;
}

.my-recipe {
  width: 95%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.my-recipe__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.my-recipe__add-button {
  width: 200px;
  border: 0;
  border-radius: 5px;
  font-weight: 500;
  font-size: 22px;
  font-family: inherit;
  line-height: 1;
  text-align: center;
  margin: 20px 0;
  color: #000000;
  position: relative;
  background: #4d9f6f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#4d9f6f));
  background-image: linear-gradient(to bottom, #fff, #4d9f6f);
  padding: 10px;
  float: left;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.my-recipe__add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 20px;
}

.pages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 30px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.page__number {
  background-color: #4D9F6F;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  cursor: pointer;
}

.page__current {
  font-weight: 600;
  opacity: 0.5;
}
