.recipe__ingredients
  display: flex
  justify-content: space-between;
  width: 100%
  align-items: center
.ingredient__measure_text
  margin: 0 5px 0
  padding: 0
.table__td_recipe
  width: 100%;
  text-align: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
.table__td_img
  width: 100%
.text-rigth
  margin-left: auto
.table__td_buttons
  display: flex
  flex-wrap: nowrap
  width: 150px