.recipe__ingredients {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ingredient__measure_text {
  margin: 0 5px 0;
  padding: 0;
}

.table__td_recipe {
  width: 100%;
  text-align: left;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.table__td_img {
  width: 100%;
}

.text-rigth {
  margin-left: auto;
}
.table__td_user {
  width: 100%;
  text-align: left;
  align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.table__td_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  width: 150px;
}
.table__acces {
  flex-direction: column;
  gap: 5px;
}
.user__name {
  align-items: flex-start;
}
.user__acces {
  flex-wrap: wrap;
  justify-content: center;
}
.table__tr_user {
  display: grid;
  border: 0.5px solid #000;
  grid-template-columns:  repeat(7, minmax(auto, 1fr));
}
.table__thead {
  
  grid-template-columns: 200px repeat(4, 120px) repeat(2, 1fr);
}
