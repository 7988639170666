.ingredient__measure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.ingredient__measure_text {
  margin: 0px;
  padding: 0;
}

.form__label_type_table {
  width: 70px;
}
