.table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin: auto;
}

.table__tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left;
  border: 1px solid #4c4c4c;
}

.table__th {
  width: 20%;
  list-style: none;
}

.table__td {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.table__ok {
  background-color: #4d9f6f;
  color: #fff;
  height: 270px;
}

.table__td_price {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
