.item-edit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

.item-edit__button {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
