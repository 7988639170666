.sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  font-size: 22px;
}

.sidebar__list {
  list-style: none;
  padding: 5px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sidebar__item {
  padding: 10px 5px;
  border: 1px solid grey;
  margin: 5px;
}

.sidebar__nav {
  text-decoration: none;
  color: #000000;
}

.sidebar__active {
  border-image-slice: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #4d9f6f;
}

.sidebar-hidden {
  display: none;
}
