.user__list
  display: flex
  gap: 5px
  margin: 5px
  border: 1px solid grey
  padding: 10px
  flex-wrap: wrap
  justify-content: center
  overflow: hidden
.user__block_row
  display: flex
  width: 45%;
  flex-direction: column
  gap: 5px
  list-style: none
  margin: 5px
  border: 1px solid grey
  padding: 10px
  flex-wrap: wrap
  justify-content: flex-start
  overflow: hidden
.user__item
  margin: 0
  display: flex;
  align-items: center;
  gap: 10px;
.user__button
  display: flex
  align-items: center
  cursor: pointer
  background-color: transparent
  margin: 0 5px
  border: none
  padding: 0 2px
  width: 100px
  height: 60px
  align-self: center  
.user__ok
  background: url('../../../images/zelenayagaloshka.svg') no-repeat center
.user__no
  background: url('../../../images/krasniykrestik.svg') no-repeat center
.user__ok-no_active
  display: none
.user__item_meaning
  font-size: 20px
  flex-direction: column
.item__button
  width: 100px
  border: 0;
  font-weight: 500;
  font-size: 16px
  font-family: inherit
  line-height: 1;
  text-align: center;
  margin: 0;
  color: #000000;
  position: relative;
  background-image: linear-gradient(to bottom, #fff, #4d9f6f);
  padding: 8px;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4)
  &:hover
    opacity: 0.8
    cursor: pointer
.user__lists
  display: flex
  flex-direction: column
  padding: 0
.user__settings
  text-align: center;
  margin: 0;
.user__yes
  color: #009b00
  font-size: 18px
  font-weight: 900