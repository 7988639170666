// import addButtons
@import './_addButtons';

// import modal
@import './_modal';

// import form
@import './_form';

// import _colorMain
@import './_colorMain';

// import _font
@import './_font';
.root
  min-width: 303px;
  max-width: 1920px;
  box-sizing: border-box;
  margin: auto;
  display: flex
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-size: cover
  background-position-x: center
  width: 100%
  min-height: 100vh
  position: relative
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  -moz-text-size-adjust: 100%
  
.page 
  display: flex;
  flex-direction: column
  background-color: #fff;
  color: #444;
  width: 100%;
  p
    margin: 0 
  //gap: 20px
  //grid-template-columns: 10% 40% 40%
  //grid-template-rows: auto;
  //grid-template-areas: "header header header" "sidebar content content" "footer footer footer";

.item__link 
  opacity: 0.8
  cursor: pointer

%backgroundImage
  background-repeat: no-repeat
  border: none
  background-color: transparent
  background-size: contain
  width: 30px
  height: 30px
.gg-delete
  @extend %backgroundImage
  background-image: url(../images/delete.svg)
  &:hover
    cursor: pointer
    opacity: 0.6
  margin-left: auto;
.gg-edit
  @extend %backgroundImage
  background-image: url(../images/iconEdit.png)
.gg-heart
  background-image: url(../images/heart.svg)
  @extend %backgroundImage
.gg-list
  background-image: url(../images/add-list.svg)
  @extend %backgroundImage
.gg-close
  background-image: url(../images/close.svg)
  @extend %backgroundImage
  position: absolute;
  right: 5px;
  top: 5px;
  transition: opacity 0.3s
  padding: 0
.gg-save
  @extend %backgroundImage
  background-image: url(../images/save.png)
  @media screen
    @media (max-width: 320px)
      width: 20px 
.gg-copy
  @extend %backgroundImage
  background-image: url(../images/copy.png)
  @media screen
    @media (max-width: 320px)
      width: 20px 
.gg-cross
  @extend %backgroundImage
  margin: 0
  background-image: url(../images/cross.png)
.gg-timing
  @extend %backgroundImage
  background-image: url(../images/time.svg)
  width: 20%
  display: flex;
  justify-content: center
  align-items: center
  padding-left: 32px
.gg-add
  @extend %backgroundImage
  background-image: url(../images/add-r.svg)
  padding-left: 25px
  width: 200px
  margin: 10px auto
  text-align: center
  font-size: 26px
.gg-sort
  @extend %backgroundImage
  background-image: url(../images/sort.png)
  width: 20px
  padding-left: 25px
  &:hover
    cursor: pointer
    opacity: 0.6
.gg-accept
  @extend %backgroundImage
  background-image: url(../images/zelenayagaloshka.svg)
  font-size: 26px
.gg-no-accept
  @extend %backgroundImage
  background-image: url(../images/krasniykrestik.svg)


.sidebar
  grid-area: sidebar
.file
  display: flex;
  flex-direction: column;
  gap: 20px;
.content 
  width: 90%
  margin: auto
  display: flex
  flex-direction: column


.header
  grid-area: header

.footer 
  grid-area: footer