.text-area__block
  width: 95%
  margin: auto
  position: relative
.text-area
  min-height: 200px
  width: 100%
.text-area::placeholder
  padding: 15px
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
.text-area_title
  text-align: center
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
.text-area__count
  align-self: self-end;
  margin-right: 100px;
.text-area__close
  right: -10px
  top: 40px
.text-area__content
  display: flex
