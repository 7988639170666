.form
  width: 90%;
  margin: 10px auto
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center;
.form__fieldset
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 10px;
  gap: 10px;
  margin: 10px auto
  width: 100%
  text-align: center;
.form__fieldset_column
  flex-direction: column
.form__label
  position: relative
  margin: 5px
  display: flex
  flex-direction: column
  align-items: center
  width: 100%;
.form__label_type_row
  flex-direction: row
  justify-content: center;
.form__input
  display: block
  width: 100%
  margin: 0
  height: 40px
  padding: 2px 5px
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #bdbdbd
  border-radius: 0.25rem
  box-sizing: border-box
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  &:focus
    outline: 0;
    outline-offset: 0 
.form__block
  display: flex
  flex-wrap: wrap
  gap: 5px
  margin: 5px;
  flex-direction: column
  width: 100%
  align-items: center;
.form__block_time
  width: fit-content
.form__block_row
  display: flex
  flex-direction: row
  margin: 5px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center
  gap: 10px
.form__block_row_nowrap
  flex-wrap: nowrap
.form__block_row_wrap
  flex-wrap:  wrap
.form__heading
  margin-bottom: 20px
  text-align: center
  padding: 10px;
  margin: 0;
.form__input_error
  color: red
.form__title
  margin: 0
  padding: 0
  text-align: center
.form__fieldset_type_measure
  align-items: flex-start
  justify-content: flex-start
.form__list
  display: flex
  align-items: flex-start
  justify-content: flex-start
  padding: 0
  margin: 0
  gap: 5px
  font-size: 20px
  font-weight: 600
  flex-wrap: wrap;
.form__label_type_measure
  width: 18%
.form__label_type_title
   width: 48%
.form__label_type_category
   width: 48%
.form__label_type_url
  width: 90%;
  margin: 10px auto;
.form__label_type_activeCooking
  width: fit-content;
.form__add-step
  padding-left: 30px;
  align-self: center;
.form__block_column
  display: flex;
  flex-direction: column;
  width: 100%
.form__input_type_activeCooking
  max-width: 120px
  margin: 6px 0
@media screen
  @media (max-width: 767px)
    .form__label_type_title
      width: 100%
    .form__label_type_category
      width: 100% 
    .form__fieldset
      flex-wrap: wrap
    