.form__signup
  display: none
  justify-content: center
  align-items: center
  margin: 0
  padding: 0

.form__open 
  display: flex

.form__text
  margin: 0
  padding: 0
  font-weight: normal
  font-size: 14px
  line-height: 17px
  text-align: center
  color: #A0A0A0
  padding-right: 6px

.form__link
  margin: 0
  padding: 0
  font-weight: normal
  font-size: 14px
  line-height: 17px
  text-align: center
  color: #3456F3
  text-decoration: none

@media screen 
  @media (max-width: 502px)
    .form__text
      font-size: 12px;
      line-height: 15px;
    .form__link
      font-size: 12px;
      line-height: 15px;