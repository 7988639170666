%fontNorm
  font-family: 'Inter';
  line-height: 1;
  font-style: normal;
.font16Norm
  @extend %fontNorm
  font-weight: 400;
  font-size: 16px; 
.font16M 
  @extend %fontNorm
  font-weight: 500;
  font-size: 16px;  
.font18Bold
  @extend %fontNorm
  font-weight: 700;
  font-size: 18px;
.font20Norm
  @extend %fontNorm
  font-weight: 400;
  font-size: 20px;
.font20M 
  @extend %fontNorm
  font-weight: 500;
  font-size: 20px;  
.font20Bold
  @extend %fontNorm
  font-weight: 700;
  font-size: 20px; 
.font24Bold
  @extend %fontNorm
  font-weight: 700;
  font-size: 24px;
.font24Norm
  @extend %fontNorm
  font-weight: 400;
  font-size: 24px;
.font26Norm
  @extend %fontNorm
  font-weight: 400;
  font-size: 26px;
.font26Bold
  @extend %fontNorm
  font-weight: 700;
  font-size: 26px;
.font35Bold
  @extend %fontNorm
  font-weight: 700;
  font-size: 35px;

@media screen
  @media (max-width: 1024px)
    .font35Bold
      font-size: 32px;
    .font26Bold
      font-size: 22px;
    .font24Norm
      font-size: 20px;
    .font20Norm
      font-size: 18px;  
    .font20Bold
      font-size: 18px;  
  @media (max-width: 768px)
    .font35Bold
      font-size: 26px;
    .font26Norm
      font-size: 20px;
    .font24Norm
      font-size: 18px;
    .font24Bold
      font-size: 18px;
    .font20Norm
      font-size: 16px;
    .font20M
      font-size: 16px;
    .font18Bold
      font-size: 14px
  @media (max-width: 520px)
    .font35Bold
      font-size: 24px;
    .font16Norm
      font-size: 14px; 
    .font26Norm
      font-size: 16px;
    .font20Norm
      font-size: 12px;
    .font20M
      font-size: 12px;
    .font24Norm
      font-size: 14px;
    .font16M 
      font-size: 10px;  
 