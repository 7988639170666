.table__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  height: 60px;
}

.table__header_item {
  width: 15%;
  border: 1px solid red;
  text-align: center;
  height: 50px;
  margin: 5px;
}
