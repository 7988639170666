.modal__container_card {
  width: 90%;
}

.modal__close {
  top: 0;
  right: 0;
}

.modal__close:hover {
  opacity: 0.6;
  cursor: pointer;
}

.modal__icon {
  width: 120px;
  height: 120px;
  margin: auto;
  margin-top: 60px;
}

.modal__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
  color: #000;
  text-align: center;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin: 10px auto;
  -ms-flex-item-align: center;
      align-self: center;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .modal__title {
    font-size: 18px;
    line-height: 22px;
  }
  .modal__close {
    width: 20px;
    top: -30px;
    right: 0;
  }
  .modal__container {
    width: 90%;
  }
}

.add-form__selected {
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
}

@media screen and (max-width: 550px) {
  .add-form__selected {
    margin-top: 10px;
    border-radius: 5px;
  }
}

.modal__container__type_modal-info {
  width: 280px;
  height: 380px;
}

.modal__title_modal-info {
  font-size: 24px;
  line-height: 29px;
  font-weight: 900;
  margin: 34px 36px 60px 36px;
}
