.about-info {
  width: 90%;
  margin: 50px auto;
}

.about-info__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about-info__text {
  text-align: center;
  font-size: 20px;
  margin: auto;
  padding: 40px;
}

.about-info_img {
  width: 40%;
  -o-object-fit: contain;
     object-fit: contain;
}
