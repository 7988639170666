.error__page {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  margin: 246px auto 60px;
}

.error__title {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 140px;
  line-height: 169px;
  text-align: center;
  margin-bottom: 5px;
}

.error__text {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 184px;
  
}

.error__link {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;  
  text-align: center;  
  color: #3456F3;
  background-color: transparent;
  border: 0;
}