.add-recipe
  width: 95%
  background-color: #fff
  border-radius: 5px
  margin: auto
  position: relative
  margin-top: 20px
.add-recipe-from
  padding: 20px
  display: flex
  flex-direction: column
  align-items: stretch
.add-recipe__level
  display: flex
  flex-direction: column
.product__cooking
  display: flex
.product-item__label
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
.product-item__choice
  position: absolute
  width: 1px
  height: 1px
  overflow: hidden  
  clip: rect(0 0 0 0)
  &:checked + .checkbox-green
    background-image: url(../../../images/checkbox.png)
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
.checkbox-green
  background-image: url(../../../images/checkbox-off.png)
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px
  width: 30px
  height: 30px
  &:active
    transform: translate(2px, 2px)
.add-recipe__fieldset
  display: flex
  justify-content: space-between
  padding: 0
  box-sizing: border-box
  width: 100%
  margin: auto
.add-recipe__label
  position: relative
  margin: 5px
  display: flex
  flex-direction: column
  align-items: center
  width: 49%
  border: 0
  padding: 0
.add-recipe__input
  display: block
  width: 100%
  margin: 0
  height: 40px
  padding: 2px 5px
  color: #212529
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #bdbdbd
  border-radius: 0.25rem
  box-sizing: border-box
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  &:focus
    outline: 0
    outline-offset: 0
  @media screen
    @media (max-width: 320px)
      font-size: 12px
.add-recipe__input_type_ingredients
  width: 100%
  border-width: 0 1px 0 0
  border-style: solid
  border-color: #b8b8b8
  border-radius: 0px
.add-recipe__button
  width: 35px
  border: 0
  background-color: transparent
  background-size: contain
  background-repeat: no-repeat
  @media screen
    @media (max-width: 520px)
      width: 20px
.add-recipe__input_type_ingredients
.add-recipe__button:hover 
  opacity: 0.8 
  cursor: pointer
.add-recipe__button_type_minus
  &:before
    content: "-"
    color: #000
    font-weight: 400
    font-size: 25px
    opacity: 0.6
.add-recipe__button_type_plus
 &:before
    content: "+"
    color: #000
    font-weight: 400
    font-size: 25px
    opacity: 0.6
 
.add-recipe__input_servings
  border: 0
  background-color: transparent
  width: 50px
  text-align: center
  font-size: 26px
  color: #000
  margin: 0
  padding: 0
  border-image: initial
  flex: 1 1 0%
  min-width: 10px
  padding: 10px
  display: flex
  -webkit-box-pack: center
  justify-content: center
  -webkit-box-align: center
  align-items: center
  width: 35px
  max-width: 48px
  font-family: "PT Serif"
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 16px
  text-transform: uppercase
  text-align: center
  border-width: 0px 1px
  border-style: solid
  border-color: #4D9F6F
  border-radius: 0px
  @media screen
    @media (max-width: 520px)
      width: 30px
      padding: 0
      height: 30px
.add-recipe__input_time
  max-width: 80px
  margin: 0 5px
 
.form__label_type_minPortion
  width: 20%
.add-recipe__autocomplete
  display: flex
  flex-direction: column
  width: 100%
  position: absolute
  background-color: #fff
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
  top: 40px
  z-index: 1
.add-recipe__block
  display: flex
  flex-wrap: wrap
  gap: 5px
  margin: 5px
.add-recipe__autocomplete_text
  padding: 10px 22px
  margin: 0
.add-recipe__autocomplete_text:hover
  background-color: #4D9F6F
.add-recipe__fieldset_type_ing
  display: flex
  margin: auto
  width: 100%
  flex-wrap: wrap
  flex-direction: column
.add-recipe__type_ing
  position: relative
  display: flex
  width: 90%
  align-items: center
  justify-content: center
  border: 1px solid #b8b8b8
  border-radius: 2px
  flex-wrap: nowrap
  @media screen
    @media (max-width: 520px)
      width: 98%
      flex-wrap: wrap;
.add-button__type_button
  margin-left: auto;

.add-recipe__ingredients
  display: flex
  flex-direction: column
  gap: 10px
  width: 100%
  justify-content: flex-start
  align-items: flex-start
  margin-bottom: 10px
.add-recipe__input_type_desc
  min-height: 300px
.add-recipe__label_type_name
  position: relative
  width: 40%
  margin: 0
  padding: 0
  border-image: initial
  flex: 1 1
  min-width: 10px
  display: flex
  justify-content: center
  align-items: center
  height: 48px
  font-family: "PT Serif"
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 16px
  text-transform: uppercase
  text-align: center
  color: black
  border-width: 0px 1px
  border-style: solid
  border-color: #b8b8b8
  border-radius: 0px
  padding: 0
.add-recipe__input_type_measure
  border: 0
  width: 70%
.add-recipe__button_type_add
  margin: 20px auto
  padding: 10px
.add-recipe__type_desc
  margin: 40px 0
.add-recipe__button_type_submit
  width: 25%
  margin-left: auto
.add-recipe__title
  font-size: 22px
  line-height: 1.5
  font-weight: 600
  margin: 20px
  text-align: center
.add-recipe__input_title
  
.add-recipe__heading
  color: #000
  font-weight: 700
  font-size: 24px
  margin-bottom: 20px
  text-align: center
  padding: 10px
  margin: 0
.image-upload
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  outline: 2px solid #f0f0f0
  width: 100%
.image-upload__label
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  width: 100%
  height: 200px
  outline: 2px dashed #f0f0f0
  outline-offset: -12px
  color: #1f3c44
  cursor: pointer
  background-image: url(../../../images/upload.png)
  background-size: 100px
  background-repeat: no-repeat
  background-position: center
  padding: 10px
.image-upload__input
  position: absolute
  width: 1px
  height: 1px
  overflow: hidden
  clip: rect(0 0 0 0)
.image-upload__visual
  position: relative
  width: 95%
  margin: 20px auto
  border-top: 1px solid #f0f0f0
  display: flex
  justify-content: flex-start
  flex-wrap: wrap
.image-upload__item
  width: 200px
  height: 200px
  object-fit: fill
  margin: 5px
.image-upload__span
  margin-top: auto
  font-size: 16px
  color: #000
.image-upload__delete
  background-image: url(../../../images/delete-image.png)
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 20px
  top: 5px
  right: 5px
  height: 20px
  cursor: pointer
.add-recipe__counter
  position: relative
  display: flex
  border: 1px solid #4D9F6F
  border-radius: 2px
.add-recipe__time
  display: flex
  align-items: center
.add-recipe__label_type_weight
  flex-direction: row
.add-recipe__label_type_measure
  width: 30%
.add-recipe__button_type_delete
  background-image: url(../../../images/delete.png)
  background-size: 10px
  background-repeat: no-repeat
  background-position: center
  width: 40px
  height: 40px
.add-recipe__type_conteiner
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: auto;
.add-recipe__input_type_ingredient-weight
  border: 0;
  max-width: 60px;
.add-recipe__input_type_ingredient-measure
  max-width: 120px;
  border: 0;
.add-recipe__type_icons
  display: flex
  gap: 1em
  margin-left: 0.5em
.add-recipe__label_type_author
  width: 100%
.add-recipe__add_ing
  background-image: url(../../../images/add-plus.png)
  background-color: transparent
  width: 25px
  height: 25px
  background-repeat: no-repeat
  background-size: contain
  border: 0
  position: absolute
  right: 5px
  top: 8px
.add-recipe__step
  display: flex
  flex-direction: column
.add-form__item-error
  color: red
.weight-error
  position: absolute
  top: 45px
.left-error
  margin-left: auto
.add-recipe__type_calories
  flex-direction: column
.add-recipe__block_row
  display: flex
  flex-wrap: wrap
  margin: 5px auto;
  padding: 0;
  justify-content: center;
.add-recipe__label_ing
  width: 18%
.text-area__block
  width: 80%
  margin: auto
  position: relative
  @media screen
    @media (max-width: 520px)
      width: 100%
      margin: 5px
.text-area
  min-height: 200px
  width: 100%
  @media screen
    @media (max-width: 520px)
      width: 90%
.text-area::placeholder
  padding: 15px
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
.text-area_title
  text-align: center
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
.text-area__count
  align-self: self-end;
  margin-right: 100px;
  margin: 0
.text-area__close
  right: -10px
  top: 45px
  margin: 0
  @media screen
    @media (max-width: 520px)
      right: 20px;
      top: 50px;
      margin: 0
.text-area__input
  min-height: 50px
  width: 100%
.form
  &::placeholder
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1
.add-recipe__label_row
  flex-direction: row
  width: 98%
  justify-content: space-between
.add-recipe__subtitle
  font-size: 18px
  margin: 0
  @media screen
    @media (max-width: 520px)
      font-size: 14px
.add-recipe__input_select
  width: 35%
  min-width: 200px
  @media screen
    @media (max-width: 520px)
      width: 100%
.add-recipe__input_select_filtr
  width: 45%
  min-width: 200px
  @media screen
    @media (max-width: 520px)
      width: 100%
.add-button__disabled 
  background-color: #D9D9D9