%Button
  height: 40px;
  border: 0
.menuweek-button
  @extend %Button
  width: 250px
.button__type
  width: fit-content
.menuweek-button:hover
  cursor: pointer
  opacity: 0.8
