.user__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  margin: 5px;
  border: 1px solid grey;
  padding: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}

.user__block_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5px;
  list-style: none;
  margin: 5px;
  border: 1px solid grey;
  padding: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow: hidden;
}

.user__item {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.user__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  background-color: transparent;
  margin: 0 5px;
  border: none;
  padding: 0 2px;
  width: 100px;
  height: 60px;
  -ms-flex-item-align: center;
      align-self: center;
}

.user__ok {
  background: url("../../../images/zelenayagaloshka.svg") no-repeat center;
}

.user__no {
  background: url("../../../images/krasniykrestik.svg") no-repeat center;
}

.user__ok-no_active {
  display: none;
}

.user__item_meaning {
  font-size: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.item__button {
  width: 100px;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  font-family: inherit;
  line-height: 1;
  text-align: center;
  margin: 0;
  color: #000000;
  position: relative;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#4d9f6f));
  background-image: linear-gradient(to bottom, #fff, #4d9f6f);
  padding: 8px;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.item__button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.user__lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
}

.user__settings {
  text-align: center;
  margin: 0;
}

.user__yes {
  color: #009b00;
  font-size: 18px;
  font-weight: 900;
}
