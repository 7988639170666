.search__label {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 0;
  padding: 0;
  margin: 5px 0;
  border-radius: 0.25rem;
  background-color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.search__none {
  display: none;
}

.search__input {
  width: 100%;
  border: 0.5px solid;
  outline: none;
  height: 40px;
  padding: 0 5px;
  border-radius: 0.25rem;
}

.search__list {
  margin: 5px;
  gap: 2px;
}

.ingredient__items {
  margin-bottom: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}

.search__input:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #4D9F6F;
}

.search__input::-webkit-input-placeholder {
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  background-size: contain;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #fff;
}

.search__input:-ms-input-placeholder {
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  background-size: contain;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #fff;
}

.search__input::-ms-input-placeholder {
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  background-size: contain;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #fff;
}

.search__input::placeholder {
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  background-size: contain;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #fff;
}

@media screen and (max-width: 520px) {
  .search__input::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    padding-left: 15px;
  }
  .search__input:-ms-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    padding-left: 15px;
  }
  .search__input::-ms-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    padding-left: 15px;
  }
  .search__input::placeholder {
    font-size: 12px;
    line-height: 20px;
    padding-left: 15px;
  }
}

.ingredient__search {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  color: #000;
}

.search__button {
  margin: auto;
  position: absolute;
  top: 0;
  right: 14px;
  bottom: 0;
}
