.ingredient {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #c9b4b4;
  min-width: 200px;
  width: 250px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fff;
  padding: 4px 10px;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ingredient__delete {
  background-image: url(../../../images/icondelete.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  width: 20px;
  height: 20px;
}

.ingredient__delete:hover {
  cursor: pointer;
  opacity: 0.6;
}

.ingredient__edit {
  background-image: url(../../../images/iconEdit.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
