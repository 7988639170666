.header {
  grid-area: header;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100px;
  margin: 30px auto;
  width: 100%;
  border-image-slice: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#4d9f6f));
  background-image: linear-gradient(to bottom, #fff, #4d9f6f);
}

.header__link_logo {
  width: 200px;
  margin: 20px;
  -o-object-fit: contain;
     object-fit: contain;
}

.header__logo {
  width: 100%;
}

.header__slogan {
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  margin: 0;
}

.header__lk {
  margin-top: 10px;
}

.header__link_type_profile {
  background-image: url(../../../images/profile-icon.png);
  padding: 8px 20px;
  background-color: #F9F9F9;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: inherit;
  background-size: 20px;
}

.header__link {
  text-decoration: none;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: inherit;
}
