@keyframes preloader-inside-white {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.preloader {
  display: flex;
  visibility: hidden;
  align-items: center; 
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10; 
  position: fixed; 
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.preloader__open {
  display: flex;
  visibility: visible;
}

.preloader__container {
  width: 110px;
  height: 110px;
  position: relative;
}

.preloader__round {
  width: 110px;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../../../images/preload.png);
  background-position: center;
  background-size: contain;
  animation: preloader-inside-white 1.5s infinite linear;
} 

.preloader_type_menu {
  position: relative;
  padding: 0; 
}
.preloader_type_page {
  position: relative;
  height: 100px;
}
.preloader__container_type__menu {
  width: 100%;
}