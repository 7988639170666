%backgroundImage
  background-repeat: no-repeat
  background-size: contain
  background-color: transparent
  border: 0
  width: 25px
  height: 22px 
.recipe-list 
  width: 90%
  margin: auto
  display: grid
  gap: 20px
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
.recipe__post 
  min-width: 300px
  max-width: 320px;
  width: 25%
  margin: 20px 10px
  background-color: #fff
  border-radius: 10px
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2)
  overflow: hidden
.card-body
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 200px;
.recipe__link 
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: center
  text-decoration: none
  color: #FFF
  transition: background-color 0.4s

.recipe-image 
  object-fit: cover
  border-top-left-radius: 10px
  border-top-right-radius: 10px

.recipe-image__place_list 
  width: 100%
  height: 200px
  object-fit: cover

.recipe__title 
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center
  overflow: hidden;
  text-overflow: ellipsis
  margin: 0;
  border-bottom: 1px solid grey;
  width: 80%
.recipe-card__title
  text-decoration: none
  text-overflow: ellipsis
  color: rgb(2, 2, 2)
  margin: auto
  font-size: 40px
  font-weight: 600
  line-height: 1.5
  text-align: center
  margin-bottom: 40px

.recipe__info-list 
  display: flex
  flex-direction: row
  justify-content: space-around
  list-style: none
  width: 90%;
  margin: auto;
.recipe-image__place_card
  width: 450px
  height: 450px


.recipe-card__desc
  display: flex
  flex-direction: column
  margin-left: 50px
  width: 100%
  border: red solid 1px
.recipe-card__ing
  width: 45%

.recipe-card__ingredients 
  text-align: center
  margin: 0
  
.recipe__ing-list 
  margin-bottom: 5px


.recipe__messure 
  padding-left: 5px

.recipe__ing 
  border-bottom: #ced0e3 solid 1px
  margin: 0
  display: flex
  justify-content: space-between
  line-height: 2;
  //font-family: ui-monospace;
  white-space: nowrap
  font-size: 18px;

.recipe-card__energyvalue_title 
  text-align: center

.recipe__ing-list::before 
  border: red solid 1px


.recipe-card__energyvalue_text 
  margin: 0


.recipe__product 
  margin: 15px
  padding-left: 15px

.recipe-card__energyvalue-list 
  display: flex
  flex-direction: column
  list-style: none
  text-align: center


.recipe-card__energyvalue_name 
  font-size: 20px
  font-weight: 600
  padding-bottom: 10px
  margin: 0


.recipe-card__energyvalue_quantity 
  margin: 0
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
.recipe-card__energyvalue_messure 
  margin: 0


.recipe-card__energyvalue-lists 
  display: flex
  flex-direction: row
  padding: 10px
  justify-content: space-between

.recipe-card__energyvalue
  width: 80%
  margin: 20px auto
  border: 1px solid #929ba3
  border-top-left-radius: 30px
  border-bottom-right-radius: 30px
.recipe__description
  width: 80%
  margin: auto
  display: flex;
  flex-direction: column
.recipe__description_title
  text-align: center
  font-size: 26px;
  line-height: 1.5;
  font-weight: 600;
.recipe__description_step
  font-size: 22px;
  line-height: 1.5;
  font-weight: 400;
.recipe__description_span
  line-height: 1.5;
  font-size: 22px;
  font-weight: 600;
  padding-right: 20px;
.recipe__add-like
  extend %backgroundImage
  right: 10px
  top: 10px
  background-image: url(../../../images/Vector.svg)
.recipe__icons
  display: flex
  justify-content: space-between
  gap: 10px
  margin-top: auto
  width: 100%
.recipe__add-like:hover
  cursor: pointer
  opacity: 0.3
.recipe__delete
  @extend %backgroundImage
  right: 10px
  top: 20px
  background-image: url(../../../images/icondelete.png)
.recipe-card__back
  @extend %backgroundImage
  background-image: url(../../../images/arrow-long-left.svg)
  margin-left: 40px
.recipe__add 
  right: 8px
  top: 40px
  background-image: url(../../../images/kisspng-computer-icons-5ae5ff28b4e7a1.709225111525022504741.jpg)
  background-repeat: no-repeat
  background-size: contain
  background-color: transparent
  border: 0
  width: 25px
  height: 22px


.recipe__add:hover 
  cursor: pointer
  opacity: 0.3


.recipe__column 
  display: flex
  justify-content: space-around
  width: 90%;
  margin: 40px auto;
.recipe-card 
  width: 95%;
  margin: auto;
  background-color: #fff;
.tags
  display: flex
  flex-wrap: wrap;
  gap: 10px
  justify-content: center
  margin: 10px
.tag
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
.tag-teal 
  background-color: #47bcd4;
.recipe-card__category
  display: flex;
  margin: auto;
  justify-content: center;
  font-size: 16px;
  color: #f9326d
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 700
  padding: 15px 0
.recipe-card__category:hover
  text-decoration: underline