.form__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 410px;
  margin: 40px auto 70px;
}

.form__info {
  width: 100%;
  margin: auto;
}

.form__data {
  border: 0;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 55px;
  height: 280px;
}

.form__item {
  border: 0;
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 10px;
}

.form__item:focus {
  outline: none;
  border-bottom: 1px solid #3456F3;
}

.input__label {
  padding-bottom: 5px;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #A0A0A0;
}

.form__button {
  width: 100%;
  height: 45px;
  background: #3456F3;
  border: 0;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: auto;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.form__button_disabled {
  background: #c6c7cc;
}

@media screen and (max-width: 850px) {
  .form__container {
    margin: 40px auto 232px;
  }
}

@media screen and (max-width: 502px) {
  .form__container {
    width: 260px;
    margin: auto;
    margin: 50px auto 30px;
  }
  .form__title {
    text-align: center;
  }
}
